import React from 'react'
import './style.css'
import worldAround from 'assets/icons/worldAround.png'
import emergencySign from 'assets/icons/emergencySign.png'
import like from 'assets/icons/like.png'
import oilCanister from 'assets/icons/oilCanister.png'
import settings from 'assets/icons/settings.png'
import timer from 'assets/icons/timer.png'
import TextLeftIconAndBottomText from 'components/common/text-left-icon-and-bottom-text'
import TextLeftQRcode from 'components/common/text-left-qrcode'
import background from 'assets/images/telematicBg.png'

function Text6 () {
  return (<>
      Снижение временных затрат на:
      <ul style={{ marginTop: '0' }}>
        <li>управление логистикой</li>
        <li>безопасность движения</li>
        <li>снижение количества штрафов и аварийности</li>
      </ul>
    </>)
}

export default function FleetManagmentContent () {
  const widthItem = '20vw'
  return (<>
      <div className="backgroundFleetManagement">
        <img className="imageFill" src={background} alt=""/>
      </div>
      <div className="mainSmartDiagnostics">
        <div className="text1FleetManagment">
          Контролируй свой автомобиль с любого устройства
        </div>
        <div className="text2FleetManagment">
          Специальное оборудование в автомобиле, бесплатно установленное на заводском конвейере, позволяет получить доступ к современным цифровым услугам платформы ГАЗ
        </div>
        <div style={{ display: 'flex', marginTop: '2%', width: '50%' }}>
          <TextLeftIconAndBottomText
            src={worldAround}
            text="Подключение GAZ Connect совершенно бесплатно"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={oilCanister}
            text="Сокращение расходов на топливо"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={settings}
            text="Возможность контролировать сохранность груза и снижение оттока клиентов"
            width={widthItem}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIconAndBottomText
            src={timer}
            text="Снижение расходов на ТО и тем самым увеличение срока службы автомобиля"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={like}
            text="Увеличение прибыли за счет снижения общего времени простоя"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={emergencySign}
            text={<Text6/>}
            width={widthItem}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%', width: '80%' }}>
          <TextLeftQRcode
            qrValue="https://play.google.com/store/apps/details?id=ru.teligent.gazApp"
            title="Скачать Google"
            width="20vw"
          />
          <TextLeftQRcode
            qrValue="https://apps.apple.com/ru/app/gaz-connect/id1424239734"
            title="Скачать AppStore"
            width="20vw"
          />
          <TextLeftQRcode
            qrValue="https://appgallery.huawei.com/app/C104031273"
            title="Скачать AppGallery"
            width="20vw"
          />
          <TextLeftQRcode
            qrValue="https://apps.rustore.ru/app/ru.teligent.gazApp"
            title="Скачать RuStore"
            width="20vw"
          />
          <TextLeftQRcode
            qrValue="https://promo.gaz-connect.ru/"
            title="Узнать подробнее"
            width="20vw"
          />
        </div>
      </div>
    </>)
}
