import React from 'react'
import './style.css'
import gearService from 'assets/icons/gearService.png'
import userCenter from 'assets/icons/userCenter.png'
import gearLoupe from 'assets/icons/gearLoupe.png'

import background from 'assets/images/maintenance-service/GAZelProfile.png'

function GearServiceText () {
  return (<>
      <ul>
        <li>Более 300 СТО</li>
        <li>Прямая коммуникация клиента с СТО (техподдержка, консультация)</li>
        <li>Запись на ТО через чат с дилером</li>
        <li>Сервисные контракты</li>
        <li>Выездное обслуживание на площадях клиента</li>
        <li>Подменный автомобиль</li>
        <li>Единые стандарты качества обслуживания клиентов и проведение сервисных работ</li>
        <li>Аттестованный сервисный персонал, способный в кратчайшие сроки определить неисправность и провести
          необходимое обслуживание
        </li>
      </ul>
    </>)
}

function UserCenterText () {
  return (<>
      <ul>
        <li>ГАЗ Ассистанс (помощь на дороге)</li>
        <li>Круглосуточный колл-центр для клиентов</li>
        <li>Экспертная поддержка для СТО</li>
        <li>Экстренные поставки запасных частей</li>
        <li>Решение по управлению и мониторингу автопарка (fleet management)</li>
      </ul>
    </>)
}

function GearLoupeText () {
  return (<>
      <ul>
        <li>Пробег и моточасы до ТО</li>
        <li>Диагностические коды неисправностей и аварийные сигнализаторы</li>
        <li>Уведомления о необходимости пройти ТО</li>
      </ul>
    </>)
}

export default function Default () {
  return (<>
    <div className="backgroundDefaultMaintenanceService">
      <img className="imageFill" src={background} alt=""/>
    </div>
    <div className="containerDefaultMaintenanceService">
      <div className="itemDefaultMaintenanceService">
        <div className="itemDefaultMaintenanceServiceTop">
          <div className="itemDefaultMaintenanceServiceTopLeft">
            <img className="imageFill" src={gearService} alt=""/>
          </div>
          <div className="itemDefaultMaintenanceServiceTopRight">
            Профессиональный сервис
          </div>
        </div>
        <div className="itemDefaultMaintenanceServiceToBottom">
          <GearServiceText/>
        </div>
      </div>
      <div className="itemDefaultMaintenanceService">
        <div className="itemDefaultMaintenanceServiceTop">
          <div className="itemDefaultMaintenanceServiceTopLeft">
            <img className="imageFill" src={userCenter} alt=""/>
          </div>
          <div className="itemDefaultMaintenanceServiceTopRight">
            Центр компетенций
          </div>
        </div>
        <div className="itemDefaultMaintenanceServiceToBottom">
          <UserCenterText/>
        </div>
      </div>
      <div className="itemDefaultMaintenanceService">
        <div className="itemDefaultMaintenanceServiceTop">
          <div className="itemDefaultMaintenanceServiceTopLeft">
            <img className="imageFill" src={gearLoupe} alt=""/>
          </div>
          <div className="itemDefaultMaintenanceServiceTopRight">
            Умная диагностика
          </div>
        </div>
        <div className="itemDefaultMaintenanceServiceToBottom">
          <GearLoupeText/>
        </div>
      </div>
    </div>

  </>)
}
