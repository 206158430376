import React from 'react'

export default function TextBelowIcon ({ src, title, width = '30vw', marginRight = '0' }) {
  const styleBlock = {
    width, marginRight
  }
  const styleContainer = {
    width: '100%', height: '100%'

  }
  const top = {
    width: '3vw', heigth: 'fit-content'
  }
  const bottom = {
    paddingTop: '2%', fontSize: '1.3vw', color: '#0dfff0'
  }
  return (
    <div style={styleBlock}>
      <div style={styleContainer}>
        <div style={top}>
          <img className="imageFill" src={src} alt=""/>
        </div>
        <div style={bottom}>
          {title}
        </div>
      </div>
    </div>
  )
}
