import React from 'react'
import TextLeftIcon from './text-left-icon'

export default function TextLeftIconAndBottomText ({ src, title, text, width = '30vw' }) {
  const styleContainer = {
    width
  }
  const top = {}
  const bottom = {
    paddingTop: '2%', paddingRight: '5%', fontSize: '1vw', color: '#0dfff0'
  }
  return (
    <div style={styleContainer}>
      <div style={top}>
        <TextLeftIcon src={src} title={title} width={width}/>
      </div>
      <div style={bottom}>
        {text}
      </div>
    </div>
  )
}
