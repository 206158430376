import React from 'react'
import cardGazAssistant from 'assets/images/loyalty-program/cardGazAssistant.png'
import cardGazOil from 'assets/images/loyalty-program/cardGazOil.png'
import './style.css'

export default function Default () {
  return (<>
    <div className="textMainLP">
      Сервисы для удобства эксплуатации автомобиля
    </div>
    <div className="blockLP">
      <div className="imageLP">
        <img className="imageFill" src={cardGazAssistant} alt=""/>
      </div>
      <div className="textRightLP">
        Профессиональная помощь на дороге в любой ситуации. Карта с бесплатным обслуживанием на 3 года
      </div>
    </div>
    <div className="blockLP">
      <div className="imageLP">
        <img className="imageFill" src={cardGazOil} alt=""/>
      </div>
      <div className="textRightLP">
        Экономия на топливе, мойках и шиномонтаже, в т. ч. за счет возмещения НДС. Широкие возможности для контроля
        бизнеса
      </div>
    </div>
  </>)
}
