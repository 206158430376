import React from 'react'
import logo2 from 'assets/images/GAZ.svg'
import logo1 from 'assets/images/STT.svg'
import title from 'assets/images/menu/title.png'

export default function Header () {
  return (<div className="header">
    <div className="headerlogo">
      <img className="logo" src={logo1} alt=""/>
    </div>
    <div className="headerCenter">
      <img className="title" src={title} alt=""/>
    </div>
    <div className="headerlogo2">
      <img className="logo" src={logo2} alt=""/>
    </div>
  </div>)
}
