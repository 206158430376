import React from 'react'
import Main from './components/main/index'
import RemoteSales from './components/remote-sales/index'
import FinancialServices from './components/financial-services'
import MaintenanceService from './components/maintenance-service'
import TelematicServices from './components/telematic-services'
import LoyaltyProgram from './components/loyalty-program'
import DevelopmentServices from './components/development-services'
import { Route, Routes } from 'react-router-dom'

export default function App () {
  return (
    <Routes>
      <Route
        path="/" element={<Main/>}/>
      <Route
        path="/remote-sales"
        element={<RemoteSales/>}>
        <Route
          path="/remote-sales/:secondRouteName"
          element={<RemoteSales/>}/>
        <Route
          path="/remote-sales/:secondRouteName/:thirdRouteName"
          element={<RemoteSales/>}/>
      </Route>
      <Route
        path="/financial-services"
        element={<FinancialServices/>}>
        <Route
          path="/financial-services/:secondRouteName"
          element={<FinancialServices/>}/>
        <Route
          path="/financial-services/:secondRouteName/:thirdRouteName"
          element={<FinancialServices/>}/>
        <Route
          path="/financial-services/:secondRouteName/:thirdRouteName/:fourthRouteName"
          element={<FinancialServices/>}/>
      </Route>
      <Route path="/maintenance-service"
             element={<MaintenanceService/>}>
        <Route
          path="/maintenance-service/:secondRouteName"
          element={<MaintenanceService/>}/>
        <Route
          path="/maintenance-service/:secondRouteName/:thirdRouteName"
          element={<MaintenanceService/>}/>
        <Route
          path="/maintenance-service/:secondRouteName/:thirdRouteName/:fourthRouteName"
          element={<MaintenanceService/>}/>
      </Route>
      <Route path="/telematic-services"
             element={<TelematicServices/>}>
        <Route
          path="/telematic-services/:secondRouteName"
          element={<TelematicServices/>}/>
        <Route
          path="/telematic-services/:secondRouteName/:thirdRouteName"
          element={<TelematicServices/>}/>
      </Route>
      <Route
        path="/loyalty-program"
        element={<LoyaltyProgram/>}>
        <Route
          path="/loyalty-program/:secondRouteName"
          element={<LoyaltyProgram/>}/>
      </Route>
      <Route
        path="/development-services"
        element={<DevelopmentServices/>}>
        <Route
          path="/development-services/:secondRouteName"
          element={<DevelopmentServices/>}/>
      </Route>
    </Routes>
  )
}
