import React from 'react'
import arrow from 'assets/icons/common/arrow_right.png'
import { Link } from 'react-router-dom'
import './style.css'

export default function ButtonWithArrow ({ name, link, width = '20%' }) {
  const styleContainer = {
    width,
    height: '7vh',
    display: 'flex',
    marginTop: '1%',
    border: 'solid medium #0dfff0'
  }

  return (
    <Link to={link}>
      <div style={styleContainer}>
        <div className="buttonWithArrowContainerLeft">
          {name}
        </div>
        <div className="buttonWithArrowContainerRight">
          <div className="buttonWithArrowContainerRightImage">
            <img className="imageFill" src={arrow} alt=""/>
          </div>
        </div>
      </div>
    </Link>
  )
}
