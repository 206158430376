import React from 'react'
import { useParams } from 'react-router-dom'
import './style.css'
import Menu from './menu.js'
import SubMain from '../submain'
import Default from './default'
import GazAssistence from './subpages/gaz-assistence'
import GazFuel from './subpages/gaz-fuel'

let next = true

function ContentPage () {
  const classAnimation = next ? 'contentSubPage contentSubPageAnimation1' : 'contentSubPage contentSubPageAnimation2'
  next = !next

  const { secondRouteName } = useParams()

  function getContentPage () {
    switch (secondRouteName) {
      case 'gaz-assistence':
        return <GazAssistence/>
      case 'gaz-fuel':
        return <GazFuel/>
      default:
        return <Default/>
    }
  }

  return (
    <>
      <div className="submenu">
        <Menu/>
      </div>
      <div className={classAnimation}>
        {getContentPage()}
      </div>
    </>
  )
}

export default function LoyaltyProgram () {
  return (
    <>
      <SubMain title={'Программа лояльности'} contentPage={<ContentPage/>}/>
    </>
  )
}
