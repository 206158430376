import React from 'react'
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/video/telematic-services/reports1.mp4'
import url2 from 'assets/video/telematic-services/reports2.mp4'
import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'

function TextStep1 () {
  return (
    <div style={{ fontSize: '1.6vh' }}>
      Функция «Отчеты» в GAZ Fleet позволяет:
      <ul style={{ marginTop: '0', marginBottom: '0' }}>
        <li>За несколько кликов создать отчет за любой промежуток времени по нескольким автомобилям</li>
        <li>Отчеты сопровождаются графиками и диаграммами</li>
        <li>Дают сводную информацию по всему парку и детальную по каждому автомобилю</li>
      </ul>
      Актуальные отчеты под рукой:
      <ul style={{ marginTop: '0' }}>
        <li>Поездки и стоянки</li>
        <li>Скорость и пробег</li>
        <li>Топливо</li>
        <li>Ночные стоянки</li>
        <li>Геозоны</li>
        <li>Моточасы</li>
      </ul>
    </div>
  )
}

function TextStep2 () {
  return (
    <div style={{ fontSize: '1.6vh' }}>
      Поездки и стоянки:
      <ul style={{ marginTop: '0', marginBottom: '0' }}>
        <li>На графике легко определить простои в работе автопарка</li>
        <li>В отчете фиксируются все адреса стоянок и поездок</li>
        <li>Выстраивается маршрут передвижения автомобиля</li>
      </ul>
    </div>
  )
}

export default function Reports () {
  const { thirdRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'
  let textStep = <TextStep1/>
  let stepLink = '/telematic-services/reports/2'
  let url = url1

  switch (thirdRouteName) {
    case '2':
      step = two
      textButtonStep = 'Первый шаг'
      textStep = <TextStep2/>
      stepLink = '/telematic-services/reports/1'
      url = url2
      break
    default:
  }

  return (
    <>
      <div className="subTitle">{'Отчеты'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Система GAZ Fleet позволяет оценивать эффективность парка
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <div className="containerConfiguratorRightTop">
            <div className="digitStep">
              <img className="logo" src={step} alt=""/>
            </div>
            {textStep}
          </div>
          <div className="containerConfiguratorRightBottom">
            <Link to={stepLink}>
              <div className="containerConfiguratorButton">
                {textButtonStep}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
