import React from 'react'
import LinkSubMain from '../submain/LinkSubMain'

export default function Menu () {
  return (<>
    <LinkSubMain
      title={'Кредит'}
      firstRoute={'/financial-services'}
      secondRoute={'credit'}
    />
    <LinkSubMain
      title={'Лизинг'}
      firstRoute={'/financial-services'}
      secondRoute={'leasing'}
    />
    <LinkSubMain
      title={'Trade-in'}
      firstRoute={'/financial-services'}
      secondRoute={'tradein'}
    />
  </>)
}
