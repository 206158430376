import React from 'react'
import { useParams } from 'react-router-dom'
import CompetenceCenterDefault from './default'
import GazAssistence from './gaz-assistence'
import CustomerCallCenter from './customer-call-center'
import FleetManagment from './fleet-managment'

export default function CompetenceCenter () {
  const { thirdRouteName } = useParams()
  let component = <CompetenceCenterDefault/>
  switch (thirdRouteName) {
    case 'gaz-assistence':
      component = <GazAssistence/>
      break
    case 'customer-call-center':
      component = <CustomerCallCenter/>
      break
    case 'fleet-managment':
      component = <FleetManagment/>
      break
    default:
      component = <CompetenceCenterDefault/>
  }
  return (
    <>
      {component}
    </>
  )
}
