import React from 'react'

export default function SocialIcon ({ src }) {
  return (
    <div
      style={{
        width: '2vw', marginRight: '5%', height: 'fit-content'
      }}
    >
      <img className="imageFill" src={src} alt=""/>
    </div>)
}
