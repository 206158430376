import React from 'react'
import './style.css'
import priceHand from 'assets/icons/priceHand.png'
import speedClock from 'assets/icons/speedClock.png'
import moneyTime from 'assets/icons/moneyTime.png'
import gearService from 'assets/icons/gearService.png'
import background from 'assets/images/maintenance-service/GAZelFullFace.png'

import LinkBack from 'components/common/link-back'
import TextLeftIconAndBottomText from 'components/common/text-left-icon-and-bottom-text'

export default function ServiceContracts () {
  return (<>
      <LinkBack url="/maintenance-service/professional-service"/>
      <div className="backgroundSericeContracts">
        <img className="imageFill" src={background} alt=""/>
      </div>
      <div className="subTitle">{'Сервисные контракты'}</div>
      <div className="mainSmartDiagnostics">
        <div className="textMainSmartDiagnostics">
            В рамках программы клиентам на выбор предлагается несколько вариантов комплексного обслуживания, которые отличаются от разовых ремонтных работ сервиса фиксированной стоимостью и выгодной ценой
        </div>
        <div className="textMainSmartDiagnostics">
          <b>Преимущества</b>
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIconAndBottomText
            src={speedClock}
            title="Скорость обслуживания"
            text="Существенно сокращает время вынужденного простоя, связанного с проведением ремонта"
            width="25vw"
          />
          <TextLeftIconAndBottomText
            src={moneyTime}
            title="Планирование затрат"
            text="Возможность планирования затрат при составлении бюджета организации"
            width="25vw"
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIconAndBottomText
            src={priceHand}
            title="Стоимость"
            text="Традиционно низкая стоимость нормо-часа (в среднем на 20% меньше конкурентов) и запасных частей (в 3-5 раз ниже аналогов) позволяет максимально снизить стоимость владения автомобилем"
            width="25vw"
          />
          <TextLeftIconAndBottomText
            src={gearService}
            title="Персонал, оборудование, запчасти"
            text="Аттестованный торговый и сервисный персонал, который способен в кратчайшие сроки определить неисправность и провести необходимые ремонты как на автомобилях марки «ГАЗ», так и автомобилях других брендов "
            width="25vw"
          />
        </div>
      </div>
    </>)
}
