import React from 'react'
import { useParams } from 'react-router-dom'
import './style.css'
import Menu from './menu.js'
import SubMain from '../submain'
import Default from './default'
import Webinar from './subpages/webinar'
import Podcasts from './subpages/podcasts'
import BusinessSolutions from './subpages/business-solutions'
import Video from './video'

let next = true

function ContentPage () {
  const classAnimation = next ? 'contentSubPage contentSubPageAnimation1' : 'contentSubPage contentSubPageAnimation2'
  next = !next

  const { secondRouteName } = useParams()

  function getContentPage () {
    switch (secondRouteName) {
      case 'webinar':
        return <Webinar/>
      case 'podcasts':
        return <Podcasts/>
      case 'business-solutions':
        return <BusinessSolutions/>
      case 'video':
        return <Video/>
      default:
        return <Default/>
    }
  }

  return (
    <>
      <div className="submenu">
        <Menu/>
      </div>
      <div className={classAnimation}>
        {getContentPage()}
      </div>
    </>
  )
}

export default function DevelopmentServices () {
  return (
    <>
      <SubMain title={'Сервисы развития бизнеса клиентов'} contentPage={<ContentPage/>}/>
    </>
  )
}
