import React from 'react'
import cardChecked from 'assets/icons/cardChecked.png'
import calendarTarget from 'assets/icons/calendarTarget.png'

import chartMoney from 'assets/icons/chartMoney.png'
import charts from 'assets/icons/charts.png'

import TextBelowIcon from 'components/common/text-below-icon'

export default function BusinessSolutions () {
  const widthItem = '15vw'
  return (<>
    <div className="subTitle">{'Бизнес решения'}</div>
    <div className="mainSmartDiagnostics">
      <div className="textMainSmartDiagnostics">
        <b>Продаем не автомобиль, а готовый бизнес</b>
      </div>
      <div className="textMainSmartDiagnostics">
        Бизнес решения от CTT - это полностью проработанный бизнес план, включающий просчитанную бизнес-модель,
        эффективную маркетинговую стратегию и партнерские предложения
      </div>
      <div style={{ display: 'flex', marginTop: '4%' }}>
        <TextBelowIcon
          src={charts}
          title="Анализ рынка и маркетинговая стратегия"
          width={widthItem}
          marginRight="1%"
        />
        <TextBelowIcon
          src={calendarTarget}
          title="Программа организационно-технических мероприятий"
          width={widthItem}
          marginRight="1%"
        />
        <TextBelowIcon
          src={chartMoney}
          title="Финансовая модель бизнеса"
          width={widthItem} marginRight="1%"
        />
        <TextBelowIcon
          src={cardChecked}
          title="Чек листы и ссылки на полезные ресурсы"
          width={widthItem}
          marginRight="1%"
        />
      </div>
    </div>
  </>)
}
