import React from 'react'
import LinkSubMain from '../submain/LinkSubMain'

export default function Menu () {
  return (<>
    <LinkSubMain
      title={'Вебинары'}
      firstRoute={'/development-services'}
      secondRoute={'webinar'}
    />
    <LinkSubMain
      title={'Подкасты'}
      firstRoute={'/development-services'}
      secondRoute={'podcasts'}
    />
    <LinkSubMain
      title={'Бизнес решения'}
      firstRoute={'/development-services'}
      secondRoute={'business-solutions'}
    />
  </>)
}
