import React from 'react'
import './style.css'
import headset from 'assets/icons/headset.png'
import charts from 'assets/icons/charts.png'
import siteLearn from 'assets/icons/siteLearn.png'
import moneyTree from 'assets/icons/moneyTree.png'
import dialog from 'assets/icons/dialog.png'
import TextLeftIconAndBottomText from 'components/common/text-left-icon-and-bottom-text'
import TextLeftQRcode from 'components/common/text-left-qrcode'
import background from 'assets/images/development-services/gazCampus.png'
import ButtonWithArrow from 'components/common/button-with-arrow'
import yt from 'assets/icons/social/yt.png'
import gaz from 'assets/icons/social/gaz.png'
import vk from 'assets/icons/social/vk.png'
import tg from 'assets/icons/social/tg.png'
import gc from 'assets/icons/social/gc.png'
import fg from 'assets/icons/social/fg.png'
import ym from 'assets/icons/social/ym.png'
import ap from 'assets/icons/social/ap.png'
import email from 'assets/icons/social/email.png'
import mm from 'assets/icons/social/mm.png'
import SocialIcon from 'components/common/social-icon'

export default function Default () {
  const widthItem = '20vw'
  return (<>
      <div className="backgroundDevelopmentServices">
        <img className="imageFill" src={background} alt=""/>
      </div>
      <div className="mainSmartDiagnostics">
        <div className="textDevelopmentServices">
          GAZ Campus – образовательный проект, направленный на повышение профессиональных
          компетенций, повышение информационной грамотности в актуальных вопросах ведения бизнеса,
          развитие профессионального и личностного потенциала
        </div>
        <div style={{ display: 'flex', marginTop: '2%', width: '50%' }}>
          <TextLeftIconAndBottomText
            src={headset}
            text="Интервью, вебинары с экспертами, профессионалами в различных областях"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={charts}
            text="Готовые бизнес-решения. Собственные информационные публикации: статьи, исследования, аналитические отчеты, подкасты"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={siteLearn}
            text="Партнерские статьи, обучающие материалы, книги, исследования"
            width={widthItem}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIconAndBottomText
            src={dialog}
            text="Конференции, форумы, опросы среди профессиональных сообществ"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={moneyTree}
            text="Воркшопы в флагманских Дилерских центрах. Профессиональная поддержка бизнеса клиентов: актуальные консультации, связанные с налогами, финансами, льготами и субсидиями"
            width={widthItem}
          />
          <div className="buttonBlockDS">
            <ButtonWithArrow
              name="Видео"
              link="/development-services/video"
              width="15vw"/>
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: '2%', width: '80%' }}>
          <div style={{ width: '70%' }}>
            <div className="text2DevelopmentServices">
              Каналы коммуникации
            </div>
            <div style={{ display: 'flex', marginTop: '4%', paddingBottom: '2%' }}>
              <SocialIcon src={yt}/>
              <SocialIcon src={gaz}/>
              <SocialIcon src={vk}/>
              <SocialIcon src={tg}/>
              <SocialIcon src={gc}/>
              <SocialIcon src={fg}/>
              <SocialIcon src={ym}/>
              <SocialIcon src={ap}/>
              <SocialIcon src={email}/>
              <SocialIcon src={mm}/>
            </div>
          </div>
          <div className="blockQRDS">
            <TextLeftQRcode
              qrValue="https://azgaz.ru/campus/"
              title="Узнать подробнее"
            />
          </div>
        </div>
      </div>
    </>)
}
