import React from 'react'
import ReactPlayer from 'react-player'
import './style.css'
import LinkBack from 'components/common/link-back'
import url from 'assets/video/development-services/video1.mp4'

export default function Video () {
  return (<>
      <LinkBack url="/development-services"/>
      <div className="subTitle">{'GAZ Campus'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
        </div>
      </div>
    </>)
}
