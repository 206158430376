import React from 'react'
import ReactPlayer from 'react-player'
import url from 'assets/video/telematic-services/geofences1.mp4'

function TextStep1 () {
  return (
    <div style={{ fontSize: '1.6vh' }}>
      Функция «Геозоны» в GAZ Fleet позволяет:
      <ul style={{ marginTop: '0' }}>
        <li>Создать геозону любой формы за несколько секунд</li>
        <li>Узнавать время нахождения автомобилей внутри и вне геозон</li>
        <li>Настроить оповещение при пересечении геозоны</li>
      </ul>
    </div>
  )
}

export default function Geofences () {
  return (<>
      <div className="subTitle">{'Геозоны'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Функция «Геозоны» в GAZ Fleet позволяет контролировать посещаемость автомобиля
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <TextStep1/>
        </div>
      </div>
    </>)
}
