import React from 'react'
import './style.css'
import checkList from 'assets/icons/checkList.png'
import gearBox from 'assets/icons/gearBox.png'
import like from 'assets/icons/like.png'

import background from 'assets/images/maintenance-service/GAZelFullFaceBlack.png'
import ButtonWithArrow from 'components/common/button-with-arrow'
import TextBelowIcon from 'components/common/text-below-icon'

export default function CompetenceCenterDefault () {
  return (<>
      <div className="backgroundCompetenceCenter">
        <img className="imageFill" src={background} alt=""/>
      </div>
      <div className="mainSmartDiagnostics">
        <div className="textMainSmartDiagnostics">
          Экспертная помощь и поддержка в любых ситуациях, связанных с эксплуатацией автомобиля
        </div>
        <div style={{ marginTop: '2%' }}>
          <ButtonWithArrow
            name="ГАЗ Ассистанс"
            link="/maintenance-service/competence-center/gaz-assistence"
          />
          <ButtonWithArrow
            name="Круглосуточный колл-центр для клиентов"
            link="/maintenance-service/competence-center/customer-call-center"
          />
          <ButtonWithArrow
            name="Решения по управлению и мониторингу автопарков"
            link="/maintenance-service/competence-center/fleet-managment"
          />
        </div>
        <div className="containerSmartDiagnostics">
          <TextBelowIcon
            title="Экспертная поддержка для СТО"
            src={like}
          />
          <TextBelowIcon
            title="Экстренные поставки"
            src={gearBox}
          />
          <TextBelowIcon
            title="Корпоративные программы"
            src={checkList}
          />
        </div>
      </div>
    </>)
}
