import React from 'react'
import './style.css'
import FleetManagmentContent from '../maintenance-service/subpages/competence-center/fleet-managment-content'

export default function Default () {
  return (<>
    <div className="subTitle">&nbsp;</div>
    <FleetManagmentContent/>
  </>)
}
