import React from 'react'
import LinkSubMain from '../submain/LinkSubMain'

export default function Menu () {
  return (<>
    <LinkSubMain
      title={'Конфигуратор'}
      firstRoute={'/remote-sales'}
      secondRoute={'configurator'}
    />
    <LinkSubMain
      title={'Онлайн-витрина'}
      firstRoute={'/remote-sales'}
      secondRoute={'online-showcase'}
    />
    <LinkSubMain
      title={'Онлайн-презентация'}
      firstRoute={'/remote-sales'}
      secondRoute={'online-presentation'}
    />
    <LinkSubMain
      title={'Выездной тест-трак'}
      firstRoute={'/remote-sales'}
      secondRoute={'field-test-track'}
    />
  </>)
}
