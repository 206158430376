import React from 'react'
import ReactPlayer from 'react-player'
import url from 'assets/video/telematic-services/monitoring1.mp4'

function TextStep1 () {
  return (
    <div style={{ fontSize: '1.6vh' }}>
      Функция «Мониторинг» в GAZ Fleet позволяет:
      <ul style={{ marginTop: '0' }}>
        <li>Видеть текущее местоположение</li>
        <li>Просматривать историю поездок и остановок</li>
        <li>Проигрывать треки на карте</li>
        <li>Своевременно реагировать на технические неисправности</li>
        <li>Получать уведомления о превышении скорости</li>
      </ul>
    </div>
  )
}

export default function Monitoring () {
  return (<>
      <div className="subTitle">{'Мониторинг'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Функция «Мониторинг» в GAZ Fleet позволяет отслеживать местоположение и техническое состояние автомобиля
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <TextStep1/>
        </div>
      </div>
    </>)
}
