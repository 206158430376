import React from 'react'
import './style.css'
import '../style.css'
import background from 'assets/images/financial-services/leasing.png'
import TextLeftIconAndBottomText from 'components/common/text-left-icon-and-bottom-text'
import CardHand from 'assets/icons/cardHand.png'
import Percent10Billet from 'assets/icons/percent10Billet.png'
import Timer from 'assets/icons/timer.png'
import Like from 'assets/icons/like.png'
import MoneyTime from 'assets/icons/moneyTime.png'
import AutoKey from 'assets/icons/autoKey.png'

const widthItem = '20vw'

export default function Leasing () {
  return (<>
    <div className="backgroundCompetenceCenter">
      <img className="imageFill" src={background} alt=""/>
    </div>
    <div className="leasingGrid">
      <div className="subTitle">{'Лизинг'}</div>
      <div className="textMainSmartDiagnostics">
        Лизинг - лучший способ приобретения автомобилей юридическим лицом и ИП с помощью заемных средств
      </div>
      <div className="textMainSmartDiagnostics">
        <b>Преимущества</b>
      </div>
      <div className="leasingGrid__advantages">
        <div className="leasingGrid__advantages__item">
          <TextLeftIconAndBottomText
            src={CardHand}
            text="Лизинг без переплаты на 3 года"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={Like}
            text="Выгодное предложение по КАСКО"
            width={widthItem}
          />
        </div>
        <div className="leasingGrid__advantages__item">
          <TextLeftIconAndBottomText
            src={Percent10Billet}
            text="Первоначальный взнос от 10%"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={MoneyTime}
            text="Сезонность бизнеса учитывается в графике платежей"
            width={widthItem}
          />
        </div>
        <div className="leasingGrid__advantages__item">
          <TextLeftIconAndBottomText
            src={Timer}
            text="Срок лизинга до 60 месяцев"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={AutoKey}
            text="Действует Государственная программа льготного лизинга. Скидка 10% от стоимости автомобиля"
            width={widthItem}
          />
        </div>
      </div>
    </div>
  </>)
}
