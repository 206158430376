import React from 'react'
import ButtonWithArrow from 'components/common/button-with-arrow'
import TextBelowIcon from 'components/common/text-below-icon'
import './style.css'
import checkList from 'assets/icons/checkList.png'
import hatchback from 'assets/icons/hatchback.png'
import textBalloon from 'assets/icons/textBalloon.png'
import background from 'assets/images/maintenance-service/MapRussia.png'

export default function ProfessionalServiceDefault () {
  return (<>
    <div className="backgroundProfessionalService">
      <img className="imageFill" src={background} alt=""/>
    </div>
    <div className="subTitle">{'Профессиональный сервис'}</div>
    <div className="mainSmartDiagnostics">
      <div className="textMainSmartDiagnostics">
        На территории РФ и СНГ - более 300 сервисных центров. География присутствия - от Калининграда до Владивостока
      </div>
      <div style={{ marginTop: '2%' }}>
        <ButtonWithArrow
          name="Прямая коммуникация клиента с СТО"
          link="/maintenance-service/professional-service/direct-communication"
        />
        <ButtonWithArrow
          name="Сервисные контракты"
          link="/maintenance-service/professional-service/service-contracts"
        />
        <ButtonWithArrow
          name="Калькулятор ТО"
          link="/maintenance-service/professional-service/calculator-to"
        />
      </div>
      <div className="containerSmartDiagnostics">
        <TextBelowIcon
          title="Сервисные контракты"
          src={checkList}
        />
        <TextBelowIcon
          title="Подменный автомобиль"
          src={hatchback}
        />
        <TextBelowIcon
          title="Запись на ТО через чат"
          src={textBalloon}
        />
      </div>
    </div>
  </>)
}
