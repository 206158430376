import React from 'react'
import PriceHand from 'assets/icons/priceHand.png'
import PercentMark from 'assets/icons/percentMark.png'
import Timer from 'assets/icons/timer.png'
import PercentBillet from 'assets/icons/percentBillet.png'
import MoneyTime from 'assets/icons/moneyTime.png'
import AutoKey from 'assets/icons/autoKey.png'

import VTB from 'assets/images/banks/vtb.svg'
import Soyuz from 'assets/images/banks/soyuzb.svg'
import CEB from 'assets/images/banks/crediteurope.svg'
import OTK from 'assets/images/banks/otkb.svg'
import Sovcom from 'assets/images/banks/sovkomb.svg'

import ButtonWithArrow from 'components/common/button-with-arrow'
import TextLeftIconAndBottomText from 'components/common/text-left-icon-and-bottom-text'
import './style.css'
import '../style.css'

const widthItem = '20vw'

const bankList = [{
  id: 1, title: 'ВТБ Банк', name: VTB
}, {
  id: 2, title: 'Банк Союз', name: Soyuz
}, {
  id: 3, title: 'Кредит Европа Банк', name: CEB
}, {
  id: 4, title: 'Банк Открытие', name: OTK
}, {
  id: 5, title: 'Совкомбанк', name: Sovcom
}]

const banksList = bankList.map(bank => (<img src={bank.name} alt={bank.title} key={bank.id}/>))

export default function DefaultCredit () {
  return (<div>
    <div className="subTitle">{'Кредит'}</div>
    <div className="creditGrid">
      <div className="creditBlock" style={{ width: '70%' }}>
        <h2>Лучшие предложения по автокредиту вместе с лидерами рынка</h2>
        <div className="creditBox">
          <div>
            <TextLeftIconAndBottomText
              src={PriceHand}
              text="Фирменный кредит 0% на 4 года"
              width={widthItem}
            />
            <TextLeftIconAndBottomText
              src={PercentMark}
              text="Кредит без первоначального взноса"
              width={widthItem}
            />
            <TextLeftIconAndBottomText
              src={Timer}
              text="Низкий платеж за счёт длительных сроков кредита"
              width={widthItem}
            />
          </div>
          <div>
            <TextLeftIconAndBottomText
              src={PercentBillet}
              text="Кредит без страхования жизни и комиссий"
              width={widthItem}
            />
            <TextLeftIconAndBottomText
              src={MoneyTime}
              text="Экспресс кредит за 15 минут. Только паспорт и права"
              width={widthItem}
            />
            <TextLeftIconAndBottomText
              src={AutoKey}
              text="Старый автомобиль как первоначальный взнос"
              width={widthItem}
            />
          </div>
        </div>
        <ButtonWithArrow
          name="Кредитный калькулятор"
          link="/financial-services/credit/credit-calculator"
        />
      </div>
      <div style={{ width: '30%' }}>
        <div className="banksListGrid">
          {banksList}
        </div>
      </div>
    </div>
  </div>)
}
