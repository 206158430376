import React from 'react'
import LinkSubMain from '../submain/LinkSubMain'

export default function Menu () {
  const height = '5vh'
  return (
    <>
      <LinkSubMain
        title={'Мобильное приложение'}
        firstRoute={'/telematic-services'}
        secondRoute={'mobile-app'}
        height={height}
      />
      <LinkSubMain
        title={'Параметры'}
        firstRoute={'/telematic-services'}
        secondRoute={'parameters'}
        height={height}
      />
      <LinkSubMain
        title={'Коммуникации с дилером'}
        firstRoute={'/telematic-services'}
        secondRoute={'communication-dealer'}
        height={height}
      />
      <LinkSubMain
        title={'Мониторинг'}
        firstRoute={'/telematic-services'}
        secondRoute={'monitoring'}
        height={height}
      />
      <LinkSubMain
        title={'Уведомления'}
        firstRoute={'/telematic-services'}
        secondRoute={'notifications'}
        height={height}
      />
      <LinkSubMain
        title={'Задания'}
        firstRoute={'/telematic-services'}
        secondRoute={'tasks'}
        height={height}
      />
      <LinkSubMain
        title={'Геозоны'}
        firstRoute={'/telematic-services'}
        secondRoute={'geofences'}
        height={height}
      />
      <LinkSubMain
        title={'Отчеты'}
        firstRoute={'/telematic-services'}
        secondRoute={'reports'}
        height={height}
      />
    </>
  )
}
