import React from 'react'
import { useParams } from 'react-router-dom'
import './style.css'
import Menu from './menu.js'
import SubMain from '../submain'
import Default from './default'
import SmartDiagnostics from './subpages/smart-diagnostics'
import ProfessionalService from './subpages/professional-service'
import CompetenceCenter from './subpages/competence-center'

let next = true

function ContentPage () {
  const classAnimation = next ? 'contentSubPage contentSubPageAnimation1' : 'contentSubPage contentSubPageAnimation2'
  next = !next

  const { secondRouteName } = useParams()

  function getContentPage () {
    switch (secondRouteName) {
      case 'smart-diagnostics':
        return <SmartDiagnostics/>
      case 'professional-service':
        return <ProfessionalService/>
      case 'competence-center':
        return <CompetenceCenter/>
      default:
        return <Default/>
    }
  }

  return (
    <>
      <div className="submenu">
        <Menu/>
      </div>
      <div className={classAnimation}>
        {getContentPage()}
      </div>
    </>
  )
}

export default function MaintenanceService () {
  return (
    <>
      <SubMain title={'Сервис по техническому обслуживанию'} contentPage={<ContentPage/>}/>
    </>
  )
}
