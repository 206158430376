import React from 'react'
import ReactPlayer from 'react-player'
import TextLeftQRcode from 'components/common/text-left-qrcode'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/video/remote-sales/configurator1.mp4'
import url2 from 'assets/video/remote-sales/configurator2.mp4'

import './style.css'
import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'

export default function Configurator () {
  const { thirdRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'
  let textStep = 'Перейдите в конфигуратор по прямой ссылке или из карточки автомобиля. Пошагово сконфигурируйте свой автомобиль'
  let stepLink = '/remote-sales/configurator/2'
  let url = url1
  switch (thirdRouteName) {
    case '1':
      step = one
      textButtonStep = 'Второй шаг'
      textStep = 'Перейдите в конфигуратор по прямой ссылке или из карточки автомобиля. Пошагово сконфигурируйте свой автомобиль'
      stepLink = '/remote-sales/configurator/2'
      url = url1
      break
    case '2':
      step = two
      textButtonStep = 'Первый шаг'
      textStep = 'Перейдите к результату конфигурирования и закажите автомобиль в сервисе Онлайн-витрина'
      stepLink = '/remote-sales/configurator/1'
      url = url2
      break
    default:
  }

  return (
    <>
      <div className="subTitle">{'Конфигуратор'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerLeft">
              Конфигуратор позволяет скомпоновать и заказать продукт под потребности вашего бизнеса
            </div>
            <div className="bottomPlayerRight">
              <TextLeftQRcode
                qrValue="https://azgaz.ru/configurator/"
                title="Отсканируйте QR-код для перехода к конфигуратору"
              />
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <div className="containerConfiguratorRightTop">
            <div className="digitStep">
              <img className="logo" src={step} alt=""/>
            </div>
            {textStep}
          </div>
          <div className="containerConfiguratorRightBottom">
            <Link to={stepLink}>
              <div className="containerConfiguratorButton">
                {textButtonStep}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
