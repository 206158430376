import React from 'react'
import logo1 from 'assets/images/STT.svg'
import { Link } from 'react-router-dom'
import './style.css'

export default function Header ({ title }) {
  return (
    <div className="header">
      <div className="headerlogo1">
        <img className="logo" src={logo1} alt=""/>
      </div>
      <div className="headerCenterTitle">
        <div>
          <div className="titleSubmain">{title}</div>
        </div>
      </div>
      <Link to="/">
        <div className="linkmain"/>
      </Link>
    </div>
  )
}
