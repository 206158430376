import React from 'react'
import { useParams } from 'react-router-dom'
import SmartDiagnosticsDefault from './default'
import DiagnosticTroubleCodes from './diagnostic-trouble-codes'

export default function SmartDiagnostics () {
  const { thirdRouteName } = useParams()
  let component = <SmartDiagnosticsDefault/>
  switch (thirdRouteName) {
    case 'diagnostic-trouble-codes':
      component = <DiagnosticTroubleCodes/>
      break
    default:
      component = <SmartDiagnosticsDefault/>
  }
  return (
    <>
      {component}
    </>
  )
}
