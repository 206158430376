import React from 'react'
import menu1 from 'assets/images/menu/remote-sales.png'
import menu2 from 'assets/images/menu/financial-services.png'
import menu3 from 'assets/images/menu/maintenance-service.png'
import menu4 from 'assets/images/menu/telematic-services.png'
import menu5 from 'assets/images/menu/loyalty-program.png'
import menu6 from 'assets/images/menu/development-services.png'
import { Link } from 'react-router-dom'

export default function Menu () {
  return (
    <div className="menublock">
      <div className="menu">
        <div className="menuleft">
          <div className="menuleftcontent">
            <div className="menuitem">
              <Link to="/remote-sales">
                <img className="menu1" src={menu1} alt=""/>
              </Link>
            </div>
            <div className="menuitem">
              <Link to="/financial-services">
                <img className="menu1" src={menu2} alt=""/>
              </Link>
            </div>
            <div className="menuitem">
              <Link to="/maintenance-service">
                <img className="menu1" src={menu3} alt=""/>
              </Link>
            </div>
          </div>
        </div>
        <div className="menuright">
          <div className="menurightcontent">
            <div className="menuitem">
              <Link to="/telematic-services">
                <img className="menu1" src={menu4} alt=""/>
              </Link>
            </div>
            <div className="menuitem">
              <Link to="/loyalty-program">
                <img className="menu1" src={menu5} alt=""/>
              </Link>
            </div>
            <div className="menuitem">
              <Link to="/development-services">
                <img className="menu1" src={menu6} alt=""/>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
