import React from 'react'
import { useParams } from 'react-router-dom'
import './style.css'
import Menu from './menu.js'
import SubMain from '../submain'
import Default from './default'
import MobileApp from './subpages/mobile-app'
import Parameters from './subpages/parameters'
import CommunicationDealer from './subpages/communication-dealer'
import Monitoring from './subpages/monitoring'
import Notifications from './subpages/notifications'
import Tasks from './subpages/tasks'
import Geofences from './subpages/geofences'
import Reports from './subpages/reports'

let next = true
function ContentPage () {
  const classAnimation = next ? 'contentSubPage contentSubPageAnimation1' : 'contentSubPage contentSubPageAnimation2'
  next = !next

  const { secondRouteName } = useParams()

  function getContentPage () {
    switch (secondRouteName) {
      case 'mobile-app':
        return <MobileApp/>
      case 'parameters':
        return <Parameters/>
      case 'communication-dealer':
        return <CommunicationDealer/>
      case 'monitoring':
        return <Monitoring/>
      case 'notifications':
        return <Notifications/>
      case 'tasks':
        return <Tasks/>
      case 'geofences':
        return <Geofences/>
      case 'reports':
        return <Reports/>
      default:
        return <Default/>
    }
  }

  return (
    <>
      <div className="submenu">
        <Menu/>
      </div>
      <div className={classAnimation}>
        {getContentPage()}
      </div>
    </>
  )
}

export default function TelematicServices () {
  return (
    <>
      <SubMain title={'Телематические сервисы'} contentPage={<ContentPage/>}/>
    </>
  )
}
