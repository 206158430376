import React from 'react'
import LinkSubMain from '../submain/LinkSubMain'

export default function Menu () {
  return (<>
    <LinkSubMain
      title={'Assistance'}
      firstRoute={'/loyalty-program'}
      secondRoute={'gaz-assistence'}
    />
    <LinkSubMain
      title={'Fuel'}
      firstRoute={'/loyalty-program'}
      secondRoute={'gaz-fuel'}
    />
  </>)
}
