import React from 'react'
import back from 'assets/icons/common/back_circle.svg'
import { Link } from 'react-router-dom'

export default function LinkBack ({ url }) {
  return (
    <Link to={url}>
      <div className="linkBack">
        <img className="imageFill" src={back} alt=""/>
      </div>
    </Link>
  )
}
