import React from 'react'
import ReactPlayer from 'react-player'
import url from 'assets/video/development-services/podcasts1.mp4'
import TextLeftQRcode from 'components/common/text-left-qrcode'

export default function Podcasts () {
  return (<>
      <div className="subTitle">{'Подкасты'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
        </div>
        <div className="containerConfiguratorRight">
        </div>
      </div>
      <div className="blockBottomPlayer2">
        <div className="bottomPlayerLeft2">
          Большая онлайн библиотека подкастов. Здесь Вы найдете лучшие образовательные подкасты для бизнеса
        </div>
        <div className="bottomPlayerRight2">
          <TextLeftQRcode
            qrValue="https://azgaz.ru/podcasts/"
            title="Узнать подробнее"
          />
        </div>
      </div>
    </>)
}
