import React from 'react'
import PercentMark from 'assets/icons/percentMark.png'
import CheckList from 'assets/icons/checkList.png'
import CardHand from 'assets/icons/cardHand.png'
import PercentBillet from 'assets/icons/percentBillet.png'
import MoneyTime from 'assets/icons/moneyTime.png'
import AutoKey from 'assets/icons/autoKey.png'
import background from 'assets/images/financial-services/GAZel.png'

export default function Default () {
  return (<>
    <div className="backgroundFinancial">
      <img className="imageFill" src={background} alt=""/>
    </div>
    <div className="financialGrid">
      <div className="textMainFinancial">Выгодно! Удобно! Быстро!</div>
      <div className="financialBlock">
        <div className="financialBlockList">
          <div className="financialBlockItem">
            <div className="financialBlockItemImage">
              <img className="imageFill" src={PercentMark} alt=""/>
            </div>
            <div className="financialBlockItemText">Скидки от<br/> производителя</div>
          </div>
          <div className="financialBlockItem">
            <div className="financialBlockItemImage">
              <img className="imageFill" src={CheckList} alt=""/>
            </div>
            <div className="financialBlockItemText">Государственные<br/> программы</div>
          </div>
          <div className="financialBlockItem">
            <div className="financialBlockItemImage">
              <img className="imageFill" src={CardHand} alt=""/>
            </div>
            <div className="financialBlockItemText">Быстрое финансирование<br/> покупки</div>
          </div>
        </div>
        <div className="financialBlockList">
          <div className="financialBlockItem">
            <div className="financialBlockItemImage">
              <img className="imageFill" src={PercentBillet} alt=""/>
            </div>
            <div className="financialBlockItemText">Кредит и лизинг без<br/> переплаты</div>
          </div>
          <div className="financialBlockItem">
            <div className="financialBlockItemImage">
              <img className="imageFill" src={MoneyTime} alt=""/>
            </div>
            <div className="financialBlockItemText">Без первоначального<br/> взноса</div>
          </div>
          <div className="financialBlockItem">
            <div className="financialBlockItemImage">
              <img className="imageFill" src={AutoKey} alt=""/>
            </div>
            <div className="financialBlockItemText">Выгодный обмен Вашего<br/> автомобиля на новый</div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
