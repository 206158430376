import React from 'react'
import background from 'assets/images/financial-services/tradein.png'
import TextLeftIconAndBottomText from 'components/common/text-left-icon-and-bottom-text'
import Timer from 'assets/icons/timer.png'
import Like from 'assets/icons/like.png'
import CheckList from 'assets/icons/checkList.png'
import AutoKey from 'assets/icons/autoKey.png'
import './style.css'

const widthItem = '20vw'

export default function TradIn () {
  return (<>
    <div className="backgroundDefaultTradein">
      <img className="imageFill" src={background} alt=""/>
    </div>
    <div className="leasingGrid">
      <div className="subTitle" style={{ marginBottom: '0' }}>{'Trade in'}</div>
      <div className="textMainSmartDiagnostics" style={{ marginBottom: '0', marginTop: '0' }}>
        Замена старого автомобиля на новый автомобиль марки ГАЗ
      </div>
      <div className="textMainSmartDiagnostics" style={{ marginTop: '0' }}>
        <b>Преимущества</b>
      </div>
      <TextLeftIconAndBottomText
        src={Timer}
        text={<span><b>Быстро</b><br/>В течение дня</span>}
        width={widthItem}
      />
      <TextLeftIconAndBottomText
        src={Like}
        text={<span><b>Удобно</b><br/>Оценку проводит Дилерский центр</span>}
        width={widthItem}
      />
      <TextLeftIconAndBottomText
        src={CheckList}
        text={<span><b>Выгодно</b><br/>Объективная оценка</span>}
        width={widthItem}
      />
      <TextLeftIconAndBottomText
        src={AutoKey}
        text={<span><b>Дважды выгодно</b><br/>Скидка на приобретение нового автомобиля</span>}
        width={widthItem}
      />
    </div>
  </>)
}
