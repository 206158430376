import React from 'react'
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/video/remote-sales/online-showcase1.mp4'
import url2 from 'assets/video/remote-sales/online-showcase3.mp4'
import url3 from 'assets/video/remote-sales/online-showcase2.mp4'
import './style.css'
import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'
import three from 'assets/icons/numbers/Three.png'

export default function OnlineShowcase () {
  const { thirdRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'
  let textStep = 'Выберите интересующие вас семейство, и тип кузова'
  let stepLink = '/remote-sales/online-showcase/2'
  let url = url1

  let buttonBackStep = null
  switch (thirdRouteName) {
    case '1':
      break
    case '2':
      step = two
      textButtonStep = 'Третий шаг'
      textStep = 'Подберите подходящую модификацию и комплектацию, воспользовавшись фильтром'
      stepLink = '/remote-sales/online-showcase/3'
      url = url3
      buttonBackStep = {
        textButtonStep: 'Первый шаг',
        stepLink: '/remote-sales/online-showcase/1'
      }
      break
    case '3':
      step = three
      textButtonStep = 'Второй шаг'
      textStep = 'Выберите автомобиль и дилерский центр из предложенных в разделе «В наличии». На странице модификации дилера укажите контактные данные, получите счет и внесите обеспечительный платеж. Дождитесь звонка менеджера дилерского центра. После полной оплаты заберите автомобиль из дилерского центра или закажите его доставку'
      stepLink = '/remote-sales/online-showcase/2'
      url = url2
      buttonBackStep = null
      break
    default:
  }

  return (
    <>
      <div className="subTitle">{'Онлайн Витрина'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Онлайн-витрина - бесшовный сервис покупки автомобиля без посещения дилера. Забронируйте автомобиль в
              дилерском центре, внесите предоплату и станьте владельцем нового автомобиля ГАЗ, не выходя из дома
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <div className="containerConfiguratorRightTop">
            <div className="digitStep">
              <img className="logo" src={step} alt=""/>
            </div>
            {textStep}
          </div>
          <div className="containerConfiguratorRightBottom">
            {buttonBackStep
              ? <div className="containerConfiguratorButtonBlock">
                <Link to={buttonBackStep.stepLink}>
                  <div className="containerConfiguratorButton">
                    {buttonBackStep.textButtonStep}
                  </div>
                </Link>
              </div>
              : ''
            }
            <Link to={stepLink}>
              <div className="containerConfiguratorButton">
                {textButtonStep}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
