import React from 'react'
import './style.css'
import hatchback from 'assets/icons/hatchback.png'
import emergencySign from 'assets/icons/emergencySign.png'
import engineStart from 'assets/icons/engineStart.png'
import oilCanister from 'assets/icons/oilCanister.png'
import cards from 'assets/icons/cards.png'
import percentBillet from 'assets/icons/percentBillet.png'
import touchHand from 'assets/icons/touchHand.png'
import routeMap from 'assets/icons/routeMap.png'
import wheel from 'assets/icons/wheel.png'
import userCenter from 'assets/icons/userCenter.png'
import TextLeftIcon from 'components/common/text-left-icon'

export default function GazAssistence () {
  const widthItem = '20vw'
  const widthItem2 = '30vw'
  return (<>
      <div className="subTitle">&nbsp;</div>
      <div className="mainSmartDiagnostics">
        <div className="textMainSmartDiagnostics">
          Профессиональная программа помощи на дорогах
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={cards}
            title="Карта ГАЗ Ассистанс в подарок на 3 года"
            width={widthItem}
          />
          <TextLeftIcon
            src={routeMap}
            title="До 250 км от границ областного центра"
            width={widthItem}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={userCenter}
            title="Помощь круглосуточно и оперативно"
            width={widthItem}
          />
          <TextLeftIcon
            src={percentBillet}
            title="Дисконтная карта на СТО и магазинах Детали машин. Скидка до 7%"
            width={widthItem}
          />
        </div>
        <div className="textMainSmartDiagnostics">
          <b>Уникальные предложения</b>
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={hatchback}
            title="Эвакуация автомобиля"
            width={widthItem2}
          />
          <TextLeftIcon
            src={oilCanister}
            title="Подвоз топлива"
            width={widthItem2}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={wheel}
            title="Шиномонтаж"
            width={widthItem2}
          />
          <TextLeftIcon
            src={emergencySign}
            title="Аварийный комиссар"
            width={widthItem2}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={engineStart}
            title="Запуск двигателя"
            width={widthItem2}
          />
          <TextLeftIcon
            src={touchHand}
            title="Возможность вызвать помощь через «Эра Глонасс»"
            width={widthItem2}
          />
        </div>
      </div>
    </>)
}
