import React from 'react'
import ReactPlayer from 'react-player'
import url from 'assets/video/telematic-services/tasks1.mp4'

function TextStep1 () {
  return (
    <div style={{ fontSize: '1.6vh' }}>
      Функция «Задания» в GAZ Fleet позволяет:
      <ul style={{ marginTop: '0' }}>
        <li>Удобно и быстро указать точки назначения</li>
        <li>Контролировать исполнение - система сама построит оптимальный маршрут и уведомит при отклонении от него
        </li>
        <li>Назначить исполнителя и автомобиль</li>
      </ul>
    </div>
  )
}

export default function Tasks () {
  return (<>
    <div className="subTitle">{'Задания'}</div>
    <div className="containerConfigurator">
      <div className="containerConfiguratorLeft">
        <div className="blockPlayer">
          <ReactPlayer width="100%" height="100%" url={url} controls playing/>
        </div>
        <div className="blockBottomPlayer">
          <div className="bottomPlayerOne">
            Функция «Задания» в GAZ Fleet позволяет создавать задания и контролировать выполнение
          </div>
        </div>
      </div>
      <div className="containerConfiguratorRight">
        <TextStep1/>
      </div>
    </div>
  </>)
}
