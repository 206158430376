import React from 'react'
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/video/financial-services/credit-calculator1.mp4'
import url2 from 'assets/video/financial-services/credit-calculator2.mp4'
import url3 from 'assets/video/financial-services/credit-calculator3.mp4'

import './style.css'
import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'
import three from 'assets/icons/numbers/Three.png'
import LinkBack from 'components/common/link-back'

export default function CreditCalculator () {
  const { fourthRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'
  let textStep = 'Выберите автомобиль и модель'
  let stepLink = '/financial-services/credit/credit-calculator/2'
  let url = url1

  let buttonBackStep = null
  switch (fourthRouteName) {
    case '1':
      break
    case '2':
      step = two
      textButtonStep = 'Третий шаг'
      textStep = 'Укажите размер первоначального взноса и срок кредита'
      stepLink = '/financial-services/credit/credit-calculator/3'
      url = url2
      buttonBackStep = {
        textButtonStep: 'Первый шаг',
        stepLink: '/financial-services/credit/credit-calculator/1'
      }
      break
    case '3':
      step = three
      textButtonStep = 'Второй шаг'
      textStep = 'Выберите подходящую для Вас программу кредитования. Оформите заявку, и наши менеджеры скоро свяжутся с Вами'
      stepLink = '/financial-services/credit/credit-calculator/2'
      url = url3
      buttonBackStep = null
      break
    default:
  }

  return (
    <>
      <LinkBack url="/financial-services/credit"/>
      <div className="subTitle">{'Кредитный калькулятор'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Разнообразие предполагаемых программ предоставляет возможность выбрать индивидуальный, наиболее подходящий
              вариант кредитования для каждого клиента
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <div className="containerConfiguratorRightTop">
            <div className="digitStep">
              <img className="logo" src={step} alt=""/>
            </div>
            {textStep}
          </div>
          <div className="containerConfiguratorRightBottom">
            {buttonBackStep
              ? <div className="containerConfiguratorButtonBlock">
                <Link to={buttonBackStep.stepLink}>
                  <div className="containerConfiguratorButton">
                    {buttonBackStep.textButtonStep}
                  </div>
                </Link>
              </div>
              : ''
            }
            <Link to={stepLink}>
              <div className="containerConfiguratorButton">
                {textButtonStep}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
