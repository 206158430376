import React from 'react'
import ReactPlayer from 'react-player'
import url from 'assets/video/telematic-services/parameters1.mp4'

function TextStep1 () {
  return (
    <div style={{ fontSize: '1.6vh' }}>
      Возможно отслеживать 15 параметров:
      <ul style={{ marginTop: '0' }}>
        <li>4 технических параметра позволяют знать все о своем автомобиле: уровень топлива, зажигание (вкл/выкл),
          давление масла, температуру охлаждающей жидкости
        </li>
        <li>5 параметров для контроля технической неисправности: низкий уровень охлаждающей жидкости, неисправность
          генератора, низкий уровень тормозной жидкости, напряжение бортовой сети, критические ошибки
        </li>
        <li>геолокация - быть в курсе нахождения Вашего автомобиля</li>
        <li>2 параметра для контроля пробега автомобиля: пробег до следующего ТО, общий пробег</li>
        <li>2 базовых параметра: скорость, дата последнего выхода на связь, общий расход топлива</li>
      </ul>
    </div>
  )
}

export default function Parameters () {
  return (<>
      <div className="subTitle">{'Параметры'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Контролируй ключевые параметры для эффективного управления автопарком
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <TextStep1/>
        </div>
      </div>
    </>)
}
