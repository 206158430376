import React from 'react'
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/video/remote-sales/online-presentation1.mp4'
import url2 from 'assets/video/remote-sales/online-presentation2.mp4'
import './style.css'
import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'

function TextStep1 () {
  return (
    <>
      <p>Заказать звонок - просто!</p>
      <p>Канал коммуникации выбираете Вы</p>
    </>
  )
}

function TextStep2 () {
  return (
    <>
      <p>Демонстрация преимуществ автомобиля в режиме реального времени</p>
      <p>Вы получите ответы на свои вопросы</p>
    </>
  )
}

export default function OnlinePresentation () {
  const { thirdRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'

  let textStep = <TextStep1/>
  let stepLink = '/remote-sales/online-presentation/2'
  let url = url1

  const buttonBackStep = null
  switch (thirdRouteName) {
    case '1':
      break
    case '2':
      step = two
      textButtonStep = 'Первый шаг'
      textStep = <TextStep2/>
      stepLink = '/remote-sales/online-presentation/1'
      url = url2
      break
    default:
  }

  return (
    <>
      <div className="subTitle">{'Онлайн презентация'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              «Онлайн презентация» - сервис заказа видеодемонстрации из наличия у дилера. Позволяет клиенту выбрать свой
              автомобиль, ознакомиться с его характеристиками и сделать свой выбор безопасно и осознанно
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <div className="containerConfiguratorRightTop">
            <div className="digitStep">
              <img className="logo" src={step} alt=""/>
            </div>
            {textStep}
          </div>
          <div className="containerConfiguratorRightBottom">
            {buttonBackStep
              ? <div className="containerConfiguratorButtonBlock">
                <Link to={buttonBackStep.stepLink}>
                  <div className="containerConfiguratorButton">
                    {buttonBackStep.textButtonStep}
                  </div>
                </Link>
              </div>
              : ''
            }
            <Link to={stepLink}>
              <div className="containerConfiguratorButton">
                {textButtonStep}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
