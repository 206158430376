import React from 'react'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/images/telematic-services/mobile-app1.png'
import url2 from 'assets/images/telematic-services/mobile-app2.png'
import url3 from 'assets/images/telematic-services/mobile-app3.png'
import url4 from 'assets/images/telematic-services/mobile-app4.png'

import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'
import three from 'assets/icons/numbers/Three.png'
import four from 'assets/icons/numbers/Four.png'

function TextStep2 () {
  return (<>
    Необходимые сервисы и услуги в одном приложении:<br/>
    - GAZ Fleet<br/>
    - GAZ Campus<br/>
    - РНИС<br/>
    - Интернет-магазин ЯМЗ<br/>
    - Страхование
  </>)
}

export default function MobileApp () {
  const { thirdRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'
  let textStep = 'Приложение позволяет вести удаленный контроль за своим автопарком'
  let stepLink = '/telematic-services/mobile-app/2'
  let url = url1

  let buttonBackStep = null
  switch (thirdRouteName) {
    case '1':
      break
    case '2':
      step = two
      textButtonStep = 'Третий шаг'
      textStep = <TextStep2/>
      stepLink = '/telematic-services/mobile-app/3'
      url = url2
      buttonBackStep = {
        textButtonStep: 'Первый шаг', stepLink: '/telematic-services/mobile-app/1'
      }
      break
    case '3':
      step = three
      textButtonStep = 'Четвертый шаг'
      textStep = 'Всегда быть на связи с Вашим дилером'
      stepLink = '/telematic-services/mobile-app/4'
      url = url3
      buttonBackStep = {
        textButtonStep: 'Второй шаг', stepLink: '/telematic-services/mobile-app/2'
      }
      break
    case '4':
      step = four
      textButtonStep = 'Третий шаг'
      textStep = 'Быть в курсе новостей, акций и посещать полезные вебинары'
      stepLink = '/telematic-services/mobile-app/3'
      url = url4
      buttonBackStep = null
      break
    default:
  }

  return (<>
    <div className="subTitle">{'Мобильное приложение'}</div>
    <div className="containerConfigurator">
      <div className="containerConfiguratorLeft">
        <div className="blockPlayer">
          <img className="imageFill" src={url} alt=""/>
        </div>
        <div className="blockBottomPlayer">
          <div className="bottomPlayerOne">
            Управляй своим автопарком удаленно
          </div>
        </div>
      </div>
      <div className="containerConfiguratorRight">
        <div className="containerConfiguratorRightTop">
          <div className="digitStep">
            <img className="logo" src={step} alt=""/>
          </div>
          {textStep}
        </div>
        <div className="containerConfiguratorRightBottom">
          {buttonBackStep
            ? <div className="containerConfiguratorButtonBlock">
              <Link to={buttonBackStep.stepLink}>
                <div className="containerConfiguratorButton">
                  {buttonBackStep.textButtonStep}
                </div>
              </Link>
            </div>
            : ''}
          <Link to={stepLink}>
            <div className="containerConfiguratorButton">
              {textButtonStep}
            </div>
          </Link>
        </div>
      </div>
    </div>
  </>)
}
