import React from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { useWindowWidth } from '@react-hook/window-size'

export default function TextLeftQRcode ({ qrValue, title, width = '30vw', paddingLeftText = '10%' }) {
  // const [sizeQRcode, setSizeQRcode] = useState(useWindowWidth()/25)
  const sizeQRcode = useWindowWidth({ fps: 5 }) / 25
  const styleContainer = {
    width,
    display: 'flex'
  }
  const left = {
    width: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  const right = {
    width: '75%',
    paddingLeft: paddingLeftText,
    fontSize: '1.2vw',
    color: '#0dfff0',
    display: 'flex',
    wordBreak: 'break-word',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }

  return (
    <div style={styleContainer}>
      <div style={left}>
        <QRCodeCanvas
          value={qrValue}
          size={sizeQRcode}
          fgColor="#0dfff0"
          bgColor="#12192e"
        />
      </div>
      <div style={right}>
        {title}
      </div>
    </div>
  )
}
