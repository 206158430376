import React from 'react'
import { useParams } from 'react-router-dom'
import CreditCalculator from './credit-calculator'
import DefaultCredit from './default'

export default function Credit () {
  const { thirdRouteName } = useParams()
  let component = <DefaultCredit/>
  switch (thirdRouteName) {
    case 'credit-calculator':
      component = <CreditCalculator/>
      break
    default:
      component = <DefaultCredit/>
  }
  return (
    <>
      {component}
    </>
  )
}
