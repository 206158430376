import React from 'react'
import LinkSubMain from '../submain/LinkSubMain'

export default function Menu () {
  return (<>
    <LinkSubMain
      title={'Умная диагностика'}
      firstRoute={'/maintenance-service'}
      secondRoute={'smart-diagnostics'}
    />
    <LinkSubMain
      title={'Профессиональный сервис'}
      firstRoute={'/maintenance-service'}
      secondRoute={'professional-service'}
    />
    <LinkSubMain
      title={'Центр компетенций'}
      firstRoute={'/maintenance-service'}
      secondRoute={'competence-center'}
    />
  </>)
}
