import React from 'react'
import './style.css'
import '../../style.css'
import checkEngineRed from 'assets/icons/engine/engineRed.png'
import checkEngineYellow from 'assets/icons/engine/engineYellow.png'
import gearPanel from 'assets/images/maintenance-service/gear_panel.png'
import TextLeftIcon from 'components/common/text-left-icon'
import LinkBack from 'components/common/link-back'

export default function DiagnosticTroubleCodes () {
  return (<>
    <LinkBack url="/maintenance-service/smart-diagnostics"/>
    <div className="subTitle">{'Диагностические коды неисправностей и аварийные сигнализаторы'}</div>
    <div className="mainSmartDiagnostics">
      <div className="textMainSmartDiagnostics">
        Умная диагностика - комплексный сервис умного технического обслуживания, который обеспечивает бесперебойную
        работу вашего автопарка
      </div>
      <div className="containerDTCPanel">
        <div className="containerDTCPanelLeft">
          Информационные вкладки:<br/>
          - пробег до ТО<br/>
          - моточасы до ТО<br/>
          Если значение оставшегося пробега или времени до ТО (для
          автомобилей с двигателем
          ISF2.8) подходит к контрольной отметке, на экране
          комбинации приборов
          отображается предупреждение «Требуется техническое
          обслуживание»
        </div>
        <div className="containerDTCPanelRight">
          <img className="imageFill" src={gearPanel} alt=""/>
        </div>
      </div>
      <div className="containerDefaultMaintenanceService">
        <div className="itemDefaultMaintenanceService">
          <div className="itemDefaultMaintenanceServiceTop">
            <TextLeftIcon title="Сигнализатор критической неисправности двигателя" src={checkEngineRed}/>
          </div>
          <div className="itemDTCBottom">
            При непрерывном горении информирует водителя о наличии критической неисправности при которой необходимо
            прекратить движение и остановить двигатель
          </div>
        </div>
        <div className="itemDefaultMaintenanceService">
          <div className="itemDefaultMaintenanceServiceTop">
            <TextLeftIcon title="Сигнализатор MIL" src={checkEngineYellow}/>
          </div>
          <div className="itemDTCBottom">
            Информирует водителя о неисправностях, фиксируемых бортовой диагностической системой, имеющих отношение к
            выбросам отработавших газов и твердых частиц. При непрерывном горении сигнализатора необходимо провести
            диагностику системы управления двигателем у официального дилера ГАЗ
          </div>
        </div>
        <div className="itemDefaultMaintenanceService">
          <div className="itemDefaultMaintenanceServiceTop">
            <TextLeftIcon title="Cигнализатор «Внимание» системы управления двигателем" src={checkEngineYellow}/>
          </div>
          <div className="itemDTCBottom">
            При непрерывном горении информирует водителя о наличии не критической неисправности, при которой водитель
            может продолжить движение. В этом случае требуется диагностика автомобиля у официального дилера ГАЗ
          </div>
        </div>
      </div>
    </div>
  </>)
}
