import React from 'react'

export default function TextLeftIcon ({ src, title, width = '30vw' }) {
  const styleContainer = {
    width, display: 'flex'
  }
  const left = {
    width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center'
  }
  const right = {
    width: '85%',
    paddingLeft: '4%',
    fontSize: '1.2vw',
    color: '#0dfff0',
    display: 'flex',
    wordBreak: 'break-word',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
  return (
    <div style={styleContainer}>
      <div style={left}>
        <div style={{ width: '3vw' }}>
          <img className="imageFill" src={src} alt=""/>
        </div>
      </div>
      <div style={right}>
        {title}
      </div>
    </div>
  )
}
