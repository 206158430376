import React from 'react'
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/video/maintenance-service/calculator-to1.mp4'
import url2 from 'assets/video/maintenance-service/calculator-to2.mp4'
import url3 from 'assets/video/maintenance-service/calculator-to3.mp4'

import './style.css'
import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'
import three from 'assets/icons/numbers/Three.png'
import LinkBack from 'components/common/link-back'

export default function CalculatorTO () {
  const { fourthRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'
  let textStep = 'Для начала расчета стоимости ТО укажите параметры своего автомобиля'
  let stepLink = '/maintenance-service/professional-service/calculator-to/2'
  let url = url1

  let buttonBackStep = null
  switch (fourthRouteName) {
    case '1':
      break
    case '2':
      step = two
      textButtonStep = 'Третий шаг'
      textStep = 'Выберите удобный для вас сервисный центр'
      stepLink = '/maintenance-service/professional-service/calculator-to/3'
      url = url2
      buttonBackStep = {
        textButtonStep: 'Первый шаг',
        stepLink: '/maintenance-service/professional-service/calculator-to/1'
      }
      break
    case '3':
      step = three
      textButtonStep = 'Второй шаг'
      textStep = 'Заполните заявку и отправьте ее, менеджер сервисного центра свяжется с Вами'
      stepLink = '/maintenance-service/professional-service/calculator-to/2'
      url = url3
      buttonBackStep = null
      break
    default:
  }

  return (
    <>
      <LinkBack url="/maintenance-service/professional-service"/>
      <div className="subTitle">{'Калькулятор ТО'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Конфигуратор расчета стоимости ТО
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <div className="containerConfiguratorRightTop">
            <div className="digitStep">
              <img className="logo" src={step} alt=""/>
            </div>
            {textStep}
          </div>
          <div className="containerConfiguratorRightBottom">
            {buttonBackStep
              ? <div className="containerConfiguratorButtonBlock">
                <Link to={buttonBackStep.stepLink}>
                  <div className="containerConfiguratorButton">
                    {buttonBackStep.textButtonStep}
                  </div>
                </Link>
              </div>
              : ''
            }
            <Link to={stepLink}>
              <div className="containerConfiguratorButton">
                {textButtonStep}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
