import React from 'react'
import './style.css'
import textBalloon from 'assets/icons/textBalloon.png'
import speedClock from 'assets/icons/speedClock.png'
import background from 'assets/images/maintenance-service/XrayGAZel.png'
import arrow from 'assets/icons/common/arrow_right.png'
import { Link } from 'react-router-dom'

export default function SmartDiagnosticsDefault () {
  return (
    <>
      <div className="backgroundSmartDiagnostic">
        <img className="imageFill" src={background} alt=""/>
      </div>
      <div className="subTitle">{'Умная диагностика'}</div>
      <div className="mainSmartDiagnostics">
        <div className="textMainSmartDiagnostics">
          Умная диагностика - комплексный сервис умного технического обслуживания, который обеспечивает бесперебойную
          работу вашего автопарка
        </div>
        <div className="textMainSmartDiagnostics">
          <b>Преимущества</b>
        </div>
        <div className="containerSmartDiagnostics">
          <div className="containerSmartDiagnosticsLeft">
            <div className="containerSmartDiagnosticsTop">
              <img className="imageFill" src={textBalloon} alt=""/>
            </div>
            <div className="containerSmartDiagnosticsBottom">
              Уведомление о необходимости пройти ТО
            </div>
          </div>
          <div className="containerSmartDiagnosticsRight">
            <div className="containerSmartDiagnosticsTop">
              <img className="imageFill" src={speedClock} alt=""/>
            </div>
            <div className="containerSmartDiagnosticsBottom">
              Пробег и моточасы до ТО
            </div>
          </div>
        </div>
        <Link to="/maintenance-service/smart-diagnostics/diagnostic-trouble-codes">
          <div className="buttonSmartDiagnostics">
            <div className="buttonSmartDiagnosticsLeft">
              Диагностические коды неисправностей и аварийные сигнализаторы
            </div>
            <div className="buttonSmartDiagnosticsRight">
              <div className="buttonSmartDiagnosticsRightImage">
                <img className="imageFill" src={arrow} alt=""/>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}
