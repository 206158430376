import React from 'react'
import './style.css'
import GazOneTwoThreeFour from 'assets/images/remote-sales/GazOneTwoThreeFour.png'

export default function Default () {
  return (<>
    <div>
      <div className="textMain">
        Дистанционные продажи - возможность покупки автомобиля онлайн!
      </div>
      <div className="textMainNext">
        Покупать онлайн - выгодно
      </div>
      <div className="imageBlock">
        <img className="imageFill" src={GazOneTwoThreeFour} alt=""/>
      </div>
    </div>
  </>)
}
