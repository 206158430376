import React from 'react'
import './style.css'
import background from 'assets/video/back.mp4'
import Header from '../common/header.js'
import poster from 'assets/icons/poster.png'

export default function SubMain ({ title, contentPage }) {
  return (
    <div className="App">
      <video autoPlay muted loop id="backVideo" poster={poster}>
        <source src={background}
                type="video/mp4"/>
        Your browser does not support HTML5 video.
      </video>
      <div className="content">
        <Header title={title}/>
        <div className="contentPage">
          {contentPage}
        </div>
      </div>
    </div>
  )
}
