import React from 'react'
import './style.css'
import hatchback from 'assets/icons/hatchback.png'
import alarmSystem from 'assets/icons/alarmSystem.png'
import emergencySign from 'assets/icons/emergencySign.png'
import engineStart from 'assets/icons/engineStart.png'
import oilCanister from 'assets/icons/oilCanister.png'
import phoneMap from 'assets/icons/phoneMap.png'
import phoneTravel from 'assets/icons/phoneTravel.png'
import touchHand from 'assets/icons/touchHand.png'
import truckChecked from 'assets/icons/truckChecked.png'
import wheel from 'assets/icons/wheel.png'
import wirelessKey from 'assets/icons/wirelessKey.png'
import LinkBack from 'components/common/link-back'
import TextLeftIcon from 'components/common/text-left-icon'

export default function GazAssistence () {
  const widthItem = '20vw'
  const widthItem2 = '30vw'
  return (<>
      <LinkBack url="/maintenance-service/competence-center"/>
      <div className="subTitle">{'ГАЗ Ассистанс'}</div>
      <div className="mainSmartDiagnostics">
        <div className="textMainSmartDiagnostics">
          Первая широкомасштабная профессиональная программа техпомощи на дороге для владельцев коммерческой техники
        </div>

        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={wheel}
            title="Замена колеса"
            width={widthItem}
          />
          <TextLeftIcon
            src={engineStart}
            title="Запуск двигателя"
            width={widthItem}
          />
          <TextLeftIcon
            src={oilCanister}
            title="Подвоз топлива"
            width={widthItem}
          />

        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={wirelessKey}
            title="Вскрытие автомобиля"
            width={widthItem}
          />
          <TextLeftIcon
            src={alarmSystem}
            title="Отключение сигнализации"
            width={widthItem}
          />
          <TextLeftIcon
            src={hatchback}
            title="Эвакуация автомобиля"
            width={widthItem}
          />
        </div>

        <div className="textMainSmartDiagnostics">
          <b>Уникальные предложения</b>
        </div>

        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={hatchback}
            title="Обеспечение доставки клиента до места ближайшей розничной точки продажи запчастей и обратно"
            width={widthItem2}
          />
          <TextLeftIcon
            src={phoneMap}
            title="Бронирование и оплата проживания в гостинице"
            width={widthItem2}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={phoneTravel}
            title="Услуга «Продолжения путешествия»"
            width={widthItem2}
          />
          <TextLeftIcon
            src={truckChecked}
            title="Бизнес без остановки"
            width={widthItem2}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '2%' }}>
          <TextLeftIcon
            src={emergencySign}
            title="Услуга аварийного комиссара"
            width={widthItem2}
          />
          <TextLeftIcon
            src={touchHand}
            title="Возможность вызвать помощь по кнопке «Эра Глонасс»"
            width={widthItem2}
          />
        </div>
      </div>
    </>)
}
