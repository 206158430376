import React from 'react'
import settings from 'assets/icons/settings.png'
import worldAround from 'assets/icons/worldAround.png'
import phoneMap from 'assets/icons/phoneMap.png'
import cardHand from 'assets/icons/cardHand.png'
import touchFinger from 'assets/icons/touchFinger.png'
import cards from 'assets/icons/cards.png'
import TextLeftIconAndBottomText from 'components/common/text-left-icon-and-bottom-text'

export default function GazFuel () {
  const widthItem = '20vw'
  return (<>
      <div className="subTitle">&nbsp;</div>
      <div className="mainSmartDiagnostics">
        <div className="textMainSmartDiagnostics">
          Топливная карта. Выгода. Удобство. Контроль
        </div>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto',
          marginTop: '4%',
          gridRowGap: '25%'
        }}>
          <TextLeftIconAndBottomText
            src={worldAround}
            title="Универсальная сеть приема"
            text="13500 АЗС по всей территории России"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={phoneMap}
            title="Экономия и отчетность"
            text="Пакет документов со всех АЗС партнеров для возмещения НДС"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={settings}
            title="Контроль"
            text="История заправок в личном кабинете на сайте партнера"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={cards}
            title="Онлайн"
            text="В мобильном приложении на карте указана стоимость топлива на АЗС партнеров"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={cardHand}
            title="Кредит"
            text="Предоставление кредитов до 30 дней для расчетов на АЗС"
            width={widthItem}
          />
          <TextLeftIconAndBottomText
            src={touchFinger}
            title="Дополнительные возможности"
            text="Оплата парковок. Покупка КАСКО и ОСАГО. Оплата штрафов"
            width={widthItem}
          />
        </div>
      </div>
    </>)
}
