import React from 'react'
import './style.css'
import LinkBack from 'components/common/link-back'
import FleetManagmentContent from './fleet-managment-content'

export default function FleetManagment ({ showTitle = true }) {
  return (
    <>
      <LinkBack url='/maintenance-service/competence-center'/>
      {showTitle
        ? <div className="subTitle">{'Решения по управлению и мониторингу автопарков'}</div>
        : <br/>}
      <FleetManagmentContent/>
    </>)
}
