import React from 'react'
import { Link, useParams } from 'react-router-dom'

export default function LinkSubMain ({ title, firstRoute, secondRoute, height = '6vh' }) {
  const { secondRouteName } = useParams()
  const fullRoute = firstRoute + '/' + secondRoute
  const back = '<'
  return (
    <>
      {secondRoute === secondRouteName
        ? <Link to={firstRoute}>
          <div className="submenuitemselect contentSubPageAnimation1" style={{ height }}>
            <div className="submenuitemback">{back}</div>
            <div style={{ width: '7%' }}></div>
            <div className="submenuitembackblock"><b className="submenuitembacktext">{title}</b></div>
          </div>
        </Link>
        : <Link to={fullRoute}>
          <div className="submenuitem" style={{ height }}>
            <div className="submenuitemtext">{title}</div>
          </div>
        </Link>
      }
    </>
  )
}
