import React from 'react'
import ReactPlayer from 'react-player'
import TextLeftQRcode from 'components/common/text-left-qrcode'
import url from 'assets/video/remote-sales/field-test-track1.mp4'
import './style.css'

function TextStep () {
  return (<>
      <p>Взять автомобиль в тест-трак - просто!</p>
      <p>Выберите одну из популярных моделей ГАЗ</p>
      <p>Возьмите автомобиль в бесплатное пользование на срок от 2 до 14 дней</p>
      <p>Оцените все технические, функциональные, эксплутационные и качественные характеристики автомобиля ГАЗ</p>
      <p>Вы поймете, что коммерческий автомобиль ГАЗ - эффективный инструмент для вашего бизнеса</p>
    </>)
}

export default function FieldTestTrack () {
  return (<>
    <div className="subTitle">{'Выездной тест-трак'}</div>
    <div className="containerConfigurator">
      <div className="containerConfiguratorLeft">
        <div className="blockPlayer">
          <ReactPlayer width="100%" height="100%" url={url} controls playing/>
        </div>
      </div>
      <div className="containerConfiguratorRight">
        <div className="containerConfiguratorRightTop">
          <TextStep/>
        </div>
        <div className="containerConfiguratorRightBottom">
        </div>
      </div>
    </div>
    <div className="blockBottomPlayer2">
      <div className="bottomPlayerLeft2">
        Мы уверены в наших автомобилях и хотим, чтобы вы убедились в преимуществах автомобилей ГАЗ.<br/>
        ГАЗ предлагает не просто тест-драйв, а 14 дней бесплатного использования коммерческого автомобиля
      </div>
      <div className="bottomPlayerRight2">
        <TextLeftQRcode
          qrValue="https://azgaz.ru/help/test-truck/"
          title="Узнать подробнее"
          paddingLeftText="5%"
        />
      </div>
    </div>
  </>)
}
