import React from 'react'
import ReactPlayer from 'react-player'
import { Link, useParams } from 'react-router-dom'
import url1 from 'assets/video/maintenance-service/direct-communication1.mp4'
import url2 from 'assets/video/maintenance-service/direct-communication2.mp4'

import './style.css'
import one from 'assets/icons/numbers/One.png'
import two from 'assets/icons/numbers/Two.png'
import LinkBack from 'components/common/link-back'

function TextStep1 () {
  return (
    <div>
      Сокращение времени простоя автопарка:
      <ul style={{ marginTop: '0' }}>
        <li>Оперативное решение Ваших вопросов и предоставление информации по автомобилю</li>
        <li>Подключение современных уведомлений по сервисному обслуживанию и критических ошибках эксплуатации
          автомобиля
        </li>
        <li>Получение персональных предложений</li>
        <li>Всегда удобное время записи для диагностических и рабочих операций</li>
        <li>Персональный план работ по Вашему автомобилю с расширенными возможностями диагностики</li>
      </ul>
    </div>
  )
}

export default function DirectCommunication () {
  const { fourthRouteName } = useParams()
  let step = one
  let textButtonStep = 'Второй шаг'
  let textStep = <TextStep1/>
  let stepLink = '/maintenance-service/professional-service/direct-communication/2'
  let url = url1

  switch (fourthRouteName) {
    case '2':
      step = two
      textButtonStep = 'Первый шаг'
      textStep = 'Возможности при подключении'
      stepLink = '/maintenance-service/professional-service/direct-communication/1'
      url = url2
      break
    default:
  }

  return (
    <>
      <LinkBack url="/maintenance-service/professional-service"/>
      <div className="subTitle">{'Прямая коммуникация с СТО'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Подписка автомобиля на дилерский центр - функция, предназначенная для повышения качества обслуживания со
              стороны дилерского центра
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          <div className="containerConfiguratorRightTop">
            <div className="digitStep">
              <img className="logo" src={step} alt=""/>
            </div>
            {textStep}
          </div>
          <div className="containerConfiguratorRightBottom">
            <Link to={stepLink}>
              <div className="containerConfiguratorButton">
                {textButtonStep}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
