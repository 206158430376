import React from 'react'
import './style.css'
import Header from './header.js'
import Menu from './menu.js'
import background from 'assets/video/intro.mp4'
import poster from 'assets/icons/poster.png'

export default function Main () {
  return (
    <div className="App">
    <video className="contentSubPageAnimation1" autoPlay muted loop id="mainVideo" poster={poster}>
      <source
        src={background}
        type="video/mp4"
      />
      Your browser does not support HTML5 video.
    </video>
    <div className="content contentSubPageAnimation1">
      <Header/>
      <Menu/>
    </div>
  </div>
  )
}
