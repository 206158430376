import React from 'react'
import { useParams } from 'react-router-dom'
import ProfessionalServiceDefault from './default'
import DirectCommunication from './direct-communication'
import CalculatorTO from './calculator-to'
import ServiceContracts from './service-contracts'

export default function ProfessionalService () {
  const { thirdRouteName } = useParams()
  let component = <ProfessionalServiceDefault/>
  switch (thirdRouteName) {
    case 'direct-communication':
      component = <DirectCommunication/>
      break
    case 'service-contracts':
      component = <ServiceContracts/>
      break
    case 'calculator-to':
      component = <CalculatorTO/>
      break
    default:
      component = <ProfessionalServiceDefault/>
  }
  return (
    <>
      {component}
    </>
  )
}
