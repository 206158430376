import React from 'react'
import ReactPlayer from 'react-player'
import url from 'assets/video/development-services/webinar1.mp4'
import TextLeftQRcode from 'components/common/text-left-qrcode'

export default function Webinar () {
  return (<>
    <div className="subTitle">{'Вебинар'}</div>
    <div className="containerConfigurator">
      <div className="containerConfiguratorLeft">
        <div className="blockPlayer">
          <ReactPlayer width="100%" height="100%" url={url} controls playing/>
        </div>
      </div>
      <div className="containerConfiguratorRight">
      </div>
    </div>
    <div className="blockBottomPlayer2">
      <div className="bottomPlayerLeft2">
        На сайте на регулярной основе публикуются образовательные вебинары для развития профессиональных навыков
      </div>
      <div className="bottomPlayerRight2">
        <TextLeftQRcode
            qrValue="https://azgaz.ru/events/"
            title="Узнать подробнее"
        />
      </div>
    </div>
  </>)
}
