import React from 'react'
import ReactPlayer from 'react-player'
import url from 'assets/video/telematic-services/notifications1.mp4'

export default function Notifications () {
  return (<>
      <div className="subTitle">{'Уведомления'}</div>
      <div className="containerConfigurator">
        <div className="containerConfiguratorLeft">
          <div className="blockPlayer">
            <ReactPlayer width="100%" height="100%" url={url} controls playing muted/>
          </div>
          <div className="blockBottomPlayer">
            <div className="bottomPlayerOne">
              Функция «Уведомления» позволяет получать полезные уведомления по факту
              превышения установленной скорости или в случае дорожных происшествий.
            </div>
          </div>
        </div>
        <div className="containerConfiguratorRight">
          Функция «Уведомления» в GAZ Fleet позволяет:<br/>
          фиксировать факт превышения установленной скорости или попадания
          автомобиля в аварию. При регистрации этих событий Вы незамедлительно
          получите уведомление во всплывающем окне и на электронную почту.
        </div>
      </div>
    </>)
}
